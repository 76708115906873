<template>
      <v-container>
        <Braedcrumb />
        <Hint/>
              <Fav/>
      </v-container>
  </template>
  
  <script>
  import { defineComponent } from "vue";
  import Braedcrumb from "../components/Favourite/Breadcrumb.vue";
  import Hint from "../components/Favourite/Hint.vue";
  import Fav from "../components/Favourite/Fav.vue";
  export default defineComponent({
    components: {
      Braedcrumb,
      Fav,
      Hint
    },
  });
  </script>
  