<template>
  <section>
    <v-container>
    <Braedcrumb/>

      <v-row>
    <v-row>
    <v-col cols="12" md="3">
      <section class="accordion">
        <v-divider class="mx-4 mb-1"></v-divider>
        <v-expansion-panels elevation="0" class="accor">
          <v-expansion-panel>
            <v-expansion-panel-title>
              {{ $t("Categories") }}
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-checkbox
                :label="item.name"
                color="#A6792B"
                v-for="item in category"
                :key="item.id"
                @change="
                  productfilter(
                    $event.target.checked ? item.id : '',
                    brand_id,
                    '',
                    rating,page
                  )
                "
              ></v-checkbox>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-divider class="mx-4 mb-1"></v-divider>
        <v-expansion-panels elevation="0" class="accor">
          <v-expansion-panel>
            <v-expansion-panel-title>
              {{ $t("Brand") }}
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-checkbox
                :label="item.name"
                color="#A6792B"
                v-for="item in brands"
                :key="item.id"
                @change="
                  productfilter(
                    cats_id,
                    $event.target.checked ? item.id : '',
                    '',
                    rating,
                    page
                  )
                "
              ></v-checkbox>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-divider class="mx-4 mb-1"></v-divider>
        <v-expansion-panels elevation="0" class="accor">
          <v-expansion-panel>
            <v-expansion-panel-title>
              {{ $t("price") }}
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="inputdesign">
                <v-range-slider
                  v-model="price"
                  :step="1"
                  hide-details
                  class="align-center"
                  @change="productfilter(cats_id, brand_id, price, rating,page)"
                  max="20000"
                >
                </v-range-slider>
              </div>
              <div class="d-flex align-items-center justify-space-between">
                <div class="d-flex flex-column w-50 mx-2">
                  <label for="" class="pb-2">{{ $t("more") }}</label>
                  <v-text-field
                    variant="solo"
                    v-model="price[1]"
                    :max="price[1]"
                  ></v-text-field>
                </div>
                <div class="d-flex flex-column w-50 mx-2">
                  <label for="" class="pb-2">{{ $t("less") }}</label>
                  <v-text-field
                    variant="solo"
                    v-model="price[0]"
                    :min="price[0]"
                    :max="price[1]"
                  ></v-text-field>
                </div>
                <!-- <input type="number" v-model="range[1]" class="w-25  pa-3  border rounded" /> -->
                <!-- <input type="number" v-model="range[0]" class="w-25 pa-3 border rounded" /> -->
              </div>
              <div class="text-center">
                <v-btn
                  style="background-color: #474749; color: white"
                  class="mt-3"
                  width="120"
                  variant="text"
                  @click="productfilter(cats_id, brand_id, price, rating,page)"
                >
                  {{ $t("sure") }}
                </v-btn>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-divider class="mx-4 mb-1"></v-divider>
        <v-expansion-panels elevation="0" class="accor">
          <v-expansion-panel>
            <v-expansion-panel-title>
              {{ $t("Evaluation") }}
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <v-rating
                length="5"
                :size="32"
                v-model="rating"
                active-color="amber"
                color="#BDC4CD"
                :hover="hoverRating"
                @update:model-value="
                  productfilter(cats_id, brand_id, price, rating,page)
                "
              />
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </section>
    </v-col>
    <v-col cols="12" md="9">
      <TabProduct :productfil="productfil" :paginationfil="paginationfil" @update-prop="productfilter"   />
      <v-pagination
        class="paginate"
        v-model="page"
        :length="paginationfil.last_page"
        :total-visible="5"
        @update:model-value="productfilter(cats_id, brand_id, price, rating,page)"
        v-if="productfil.length > 0"
      >
     </v-pagination>
    </v-col>
  </v-row>
  </v-row>
  </v-container>
  </section>

</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import TabProduct from "./TabProduct.vue";
import Braedcrumb from "./Braedcrumb.vue";
export default {
  components: {
    TabProduct,
    Braedcrumb
  },
  data() {
    return {
    texts: [1, 2, 3,4,5,6],
    page: 1,
      hoverRating: null,
      category: [],
      brands: [],
      productfil: [],
      paginationfil: '',
      cats_id: "",
      brand_id: "",
      price: [0, 200000],
      rating: 0,
      page: 1,

    };
  },

  methods: {
    // products?filter[cats_id]=334&filter[name]=&filter[rating]=&filter[price]=
    async productfilter(cats_id, brand_id, price, rating,page) {
      console.log(cats_id);
      console.log(brand_id);
      console.log(price);
      (this.cats_id = cats_id),
        (this.brand_id = brand_id || ""),
        (this.price = price || ""),
        (this.rating = rating || "");
        (this.page = page || "");
      let res = await CrudDataService.getAll(
        `products?filter[cats_id]=${this.cats_id}&filter[brand_id]=${this.brand_id}&filter[price]=${this.price}&filter[rating]=${this.rating}&page=${this.page}`
      );
      this.paginationfil=res.data.data;
      this.productfil = res.data.data.data;
    },
    async allcategories() {
      let res = await CrudDataService.getAll("categories");
      this.category = res.data.data.data;
    },
    async allbrands() {
      let res = await CrudDataService.getAll("brands");
      this.brands = res.data.data.data;
    },
    updateParentProp(newVal) {
      this.parentProp = newVal;
    },
  },
  mounted() {
    this.allcategories();
    this.allbrands();
  },
};
</script>
<style>
.accor .v-expansion-panel {
  background-color: transparent;
}
.accor .v-expansion-panel__shadow {
  box-shadow: none;
}
.accor .v-checkbox .v-selection-control,
.accor .v-expansion-panel--active > .v-expansion-panel-title {
  min-height: auto;
}
.accor .v-expansion-panel-title--active > .v-expansion-panel-title__overlay,
.v-expansion-panel-title[aria-haspopup="menu"][aria-expanded="true"]
  > .v-expansion-panel-title__overlay {
  opacity: 0;
}
.accor .v-input__details {
  display: none;
}
.accor .v-expansion-panel-text__wrapper {
  padding-right: 8px;
  padding-left: 8px;
}
.accor .v-selection-control__input:hover::before {
  opacity: 0;
}
.inputdesign .v-locale--is-ltr {
  direction: ltr !important;
}
.inputdesign .v-slider-track__background--opacity {
  opacity: 1;
  background: #ffefc7;
}
.inputdesign .v-slider.v-input--horizontal .v-slider-track__fill {
  background: #474749;
}
.inputdesign .v-slider.v-input--horizontal .v-slider-thumb {
  color: #fff;
  border-color: #ffefc7;
}
</style>
