<template>
  <section class="mt-8">
    <div class="d-flex justify-space-between">
      <h2> {{ $t('Best_selling') }}</h2>
      <!-- <v-list-item
        link
        class="pa-0"
        min-height="auto"
        style="border-bottom: 1px solid #474749; color: #03dac5"
      >
        عرض الكل
      </v-list-item> -->
    </div>
    <div class="d-flex mt-5 flex-sm-row flex-column">
      <v-card
        class="mx-auto pb-4 mostcard mb-2"
        v-for="(product, i) in products"
        :key="i"
        data-aos="zoom-in-left" data-aos-duration="800"
            :data-aos-delay="100 * i"
            @click="detail(product.id)"
       
      >    
              <v-img :src="product.cover_image" class="w-100" height="300" cover >
                </v-img>

            
              <div class="pa-3 pr-3 pb-0">
                <div class="d-flex justify-space-between align-center">
                <p @click="detail(product.id)" style="cursor: pointer">
                  {{ product.name }}
                </p>
              
              </div>

              <v-card-text  class="py-1 px-0" v-if="product.discounted_price">
                  <p v-if="product.base_price" class="text-decoration-line-through text-red ">
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                <p >
                  {{
                  Math.trunc(product.discounted_price)
                }}
                {{ $t('rs') }}

                </p>
               </v-card-text>
               <v-card-text  class="py-1 px-0" v-else>
                  <p >
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                
               </v-card-text>

              <div class="d-flex flex-column">
                <v-rating
                  color="amber"
                  density="compact"
                  
                  readonly
                  size="small"
                v-model="product.rating"
                  class="px-0"
                  hover
                ></v-rating>
                <p style="color: #ff9017" v-if="product.rating">
                  {{ product.rating }}
                </p>
               
               
              </div>

              <v-card-text class="py-0 line_text" >
                {{ product.description_without_html }}
              </v-card-text>
            
              </div>
           
            </v-card>
     </div>
  </section>
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import cardimg from "../../../src/assets/image/photo.png";
export default {
  data() {
    return {
      products: [ ],
    };
  },
  methods:{
  async productlatest() {
      let res = await CrudDataService.getAll(`products?sort=--total_orders`);
      this.products = res.data.data.data.slice(0,4);
    },
    detail(id) {
      this.$router.push({ name: "Detail", params: { id } });
    },
  },
    mounted() {
    this.productlatest();
  },
};
</script>
<style scoped>
.mostcard {
  width: calc(95% / 4);
}

@media (max-width: 767.98px) {
  .mostcard {
    width: 100%;
  }
}
</style>
