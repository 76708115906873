<template>
  <section class="mt-8 package">
    <div class="d-flex justify-space-between">
      <h2>{{ $t("Latest_products") }}</h2>
      <!-- <v-list-item
        link
        class="pa-0"
        min-height="auto"
        style="border-bottom: 1px solid #474749; color: #03dac5"
      >
        عرض الكل
      </v-list-item> -->
    </div>
    <v-window
      v-model="window"
      class="elevation-0 position-relative mt-5"
      :show-arrows="false"
    >
      <v-window-item v-for="(group, i) in groupedProducts" :key="i">
        <v-row>
          <v-col
            cols="6"
            md="3"
            v-for="(product, index) in group"
            :key="index"
            class="h-100"
            data-aos="zoom-in-left"
            data-aos-duration="800"
            :data-aos-delay="100 * index"
          >
            <v-card
              class="mx-auto pb-4 mostcard mb-2"
              @click="detail(product.id)"
              flat
              style="height: 520px"
            >
            
              <img
                :src="product.cover_image"
                height="320"
                class="grey lighten-2 w-100"
                style="object-fit: cover !important"
              />
              <div class="d-flex flex-row flex-wrap mt-1 justify-center" v-if="product.first_option">
                      <div
                        v-for="item in product.first_option.values"
                        :key="item.id"
                        style="padding: 6px 0;"
                        class="mb-1"
                        >
                        <v-avatar
                          :style="{
                            backgroundColor: item.color,
                            cursor: 'pointer',
                          }"
                          class="mx-1 "
                          size="25"
                          @click="selectColor(product.variants, item.id, item)"
                        v-if="item.color"
                        :class="{ activeborder: item.active }">
                        </v-avatar>
                        <span
                     v-else
                   :class="{ active: item.active }"
                     class="sizing"
                     style="border-radius: 25px;">{{ item.value }}</span
                   >
                      </div>
                    </div> 
              <div class="pa-md-3 pa-1 pr-3 pb-0">
                <div class="d-flex justify-space-between align-center">
                  <p @click="detail(product.id)" style="cursor: pointer">
                    {{ product.name }}
                  </p>
                </div>
                <v-card-text
                  class="py-1 px-0 d-flex"
                  v-if="product.discounted_price"
                >
                  <p
                    v-if="product.base_price"
                    class="text-decoration-line-through text-red px-1"
                  >
                    {{ Math.trunc(product.base_price) }}
                    {{ $t("rs") }}
                  </p>
                  <p class="px-1">
                    {{ Math.trunc(product.discounted_price) }}
                    {{ $t("rs") }}
                  </p>
                </v-card-text>
                <v-card-text class="py-1 px-0 d-flex" v-else>
                  <p class="px-1">
                    {{ Math.trunc(product.base_price) }}
                    {{ $t("rs") }}
                  </p>
                </v-card-text>

                <div class="d-flex flex-column">
                  <v-rating
                    color="amber"
                    density="compact"
                    readonly
                    size="small"
                    v-model="product.rating"
                    class="px-0"
                    hover
                  ></v-rating>
                  <p style="color: #ff9017" v-if="product.rating">
                    {{ product.rating }}
                  </p>
                </div>

                <v-card-text class="py-0 line_text">
                  {{ product.description_without_html }}
                </v-card-text>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-window-item>
      <div
        class="position-absolute w-100 d-flex justify-space-between"
        style="top: 40%"
      >
        <v-btn icon @click="next" class="right_btn">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn icon @click="prev" class="left_btn">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </v-window>
  </section>
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import cardimg from "../../../src/assets/image/photo.png";
export default {
  data() {
    return {
      products: [],
      window: 0,
      variantoption:[]
    };
  },
  computed: {
    // Group the products into sub-arrays of 3
    groupedProducts() {
      const groups = [];
      for (let i = 0; i < this.products.length; i += 4) {
        groups.push(this.products.slice(i, i + 4));
      }
      return groups;
    },
  },
  methods: {
    // /products?sort=-total_orders
    next() {
      this.window++;
      if (this.window >= this.groupedProducts.length) {
        this.window = 0; // Wrap around to the first window
      }
    },
    prev() {
      this.window--;
      if (this.window < 0) {
        this.window = this.groupedProducts.length - 1; // Wrap around to the last window
      }
    },
    detail(id) {
      this.$router.push({ name: "Detail", params: { id } });
    },
    async productcreate() {
      let res = await CrudDataService.getAll(`products?sort=-created_at`);
      this.products = res.data.data.data;
    },
  },
  mounted() {
    this.productcreate();
  },
};
</script>
<style>
.package .v-window__controls {
  flex-direction: row-reverse;
}
.package .v-btn--icon.v-btn--density-default {
  width: 55px;
  height: 44px;
}
.package .v-btn--icon {
  border-radius: 30px;
  box-shadow: none;
}

.package .v-window__controls .v-window__left {
  left: -35px;
  background: #dddada;
  color: #fff;
}
.package .v-window__controls .v-window__right {
  right: -35px;
  background: #dddada;
  color: #fff;
}
</style>
