<template>
  <v-breadcrumbs :items="items">
      <template v-slot:title="{ item }">
        {{ $t(item.title) }}
      </template>
    </v-breadcrumbs>
</template>
<script>

export default {
data() {
 return {
  items: [
        {
          title: "Home",
          disabled: false,
          href: "/",
        },
        {
          title: "favourite",
          disabled: false,
          href: "/favourite",
        },
      ],
 };
},
};
</script>
<style >

 .v-breadcrumbs {
color: #8B96A5;
}
</style>