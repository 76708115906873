<template>
      <About />
</template>

<script>
import About from "../components/About/About.vue";

export default ({
  components: {
    About,
  },
});
</script>
