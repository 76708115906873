<template>
    <v-container>
      <offers />
    </v-container>
</template>

<script>
import offers from "../components/offers/offer.vue";

export default ({
  components: {
    offers,
  },
});
</script>
