<template>
    <v-container>
      <Complete />
    </v-container>
</template>

<script>
import { defineComponent } from "vue";
import Complete from "../components/Completeorder/Complete.vue";

export default defineComponent({
  components: {
    Complete,
  },
});
</script>
