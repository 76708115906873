<template>
  <div >
    <!-- <div v-if="$route.path == '/cart'||$route.path == '/cart/lastorder'||$route.path == '/complete-order'">
    </div> -->
    <section class="subscribe py-4 text-center">
  
    <v-col cols="12" md="4" sm="7" xs="9" class="mx-auto" >
        <div
    class="mx-auto"
    data-aos-offset="200"
      data-aos="fade-up"
  >
      <h3 class="py-1">{{ $t('Subscribe_receive_latest')}}</h3>
<p class="pb-3">
{{ $t('Get_news_world') }}
    
</p>
  <div class="d-flex justify-center align-center">
    <!-- <v-text-field
            density="compact"
            variant="solo"
            prepend-inner-icon="mdi-email-outline"
            :placeholder="$t('email')"
            single-line
            hide-details
            rounded="0"
            class="search"
          >
          </v-text-field> -->
          <v-btn
            style="background-color: #A6792B"
            class="rounded-0 text-white"
            @click="redirectto('/contact-us')"
          >
          {{ $t('subscription') }}
          </v-btn>
  </div>
  
    </div>
    </v-col>
   
  </section>
 
  </div>

</template>
<script>
import AOS from "aos";

import cardimg from "../../../src/assets/image/photo.png";
export default {
  data: () => ({
    cardimg: cardimg,
  }),
  methods:{
    redirectto(path){
      this.$router.push(path)
    },
  },
  mounted(){
    AOS.init();

  }
};
</script>
<style scoped>
.subscribe {
  background: rgba(166, 121, 43, 0.42);

}


</style>
<style >
.subscribe .search .v-input__control{
    width: 99%;

}
.subscribe .v-btn.v-btn--density-default {
    height: calc(var(--v-btn-height) + 3px);
}
.subscribe .v-text-field .v-field--single-line input {
 
    padding: 0 !important;
}
</style>