<template>
    <SignUp />
</template>

<script>
import { defineComponent } from "vue";
import SignUp from "../components/Auth/SignUp/SignUp.vue";

export default defineComponent({
components: {
    SignUp,
},
});
</script>
