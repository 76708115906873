<template>
  <v-card class="hint  py-2" elevation="0">
    <v-card-text>
      {{ $t('Save_favorite_thing') }}
    </v-card-text>
  </v-card>
</template>
<style scoped>


.hint .v-card-text {
    font-size: 18px;
}
</style>
