<template>
  <section>


    <section v-if="content">
    <v-container>
      <v-breadcrumbs :items="items">
      
      <template v-slot:title="{ item }">
        
        {{ $t(item.title) }}
      </template>
    </v-breadcrumbs>
      <div v-html="content.content" class="textline"></div>
    </v-container>

    </section>

    <!-- <section v-else-if="!content">
      <v-card class="hint my-7 py-2" elevation="0">
        <v-card-text>
          {{ $t('no_page') }}
          {{ items[1].title }}
          {{$t('now')}}
        </v-card-text>
      </v-card>
    </section> -->
    <section class="position-relative"  v-else style="height: 100vh;display: grid;
    place-items: center;">

<section class="cate">
</section>
      <!-- <v-progress-circular
      indeterminate
      color="#d6487c8a"
    ></v-progress-circular> -->
    <img :src="info" width="120">

    </section>
  </section>
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import info from "../../assets/image/Into.gif"
export default {
  data: () => ({
    info,
    content: "",
    items: [
      {
        title: 'Home',
        disabled: false,
        href: "/",
      },
      {
        title: "About",
        disabled: false,
        href: "/about-us",
      },
    ],
  }),
  methods: {
    async allpages() {
      let res = await CrudDataService.getAll("pages");
      res.data.data.forEach((element) => {
        console.log(element);
        if (element.slug == "about-us"||element.slug=="mn-nhn") {
          this.content = element;
          console.log(this.content);
        }
      });
    },
  },
  mounted() {
    this.allpages();
  },
};
</script>

<style scoped>

.hint .v-card-text {
  font-size: 18px;
}
.textline{
  line-height: 2.7;
}
</style>
