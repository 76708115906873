<template>
  <section style="background: #eeeeee">

            <Fillter/>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import Fillter from "../components/Product/Fillter.vue";
export default defineComponent({
  components: {
    Fillter,
  },
});
</script>
