<template>
      <Category />

  
</template>

<script>
import Category from "../components/Category/Category.vue";

export default ({
  components: {
    Category,
  },
});
</script>
<style scoped>

</style>