<template>
  <section>
    <v-breadcrumbs :items="items">
      <template v-slot:title="{ item }">
        {{ $t(item.title) }}
      </template>
    </v-breadcrumbs>
  <contactinput/>
  </section>
</template>
<script>
import contactinput from './contactinput.vue';
export default {
  components:{
    contactinput
  },
  data() {
    return {
      items: [
        {
          title: "Home",
          disabled: false,
          href: "/",
        },
        {
          title: "Contact",
          disabled: false,
          href: "/contact-us",
        },
      ],
    };
  },
  methods: {
   
  },
};
</script>
