<template>
    <v-card class="mx-auto my-3 pa-4 ">
        <h5 class="pb-3"> {{ $t('Other_products') }}</h5>
      <v-row class="justify-center">
        <v-col cols="5" lg="2" sm="4" v-for="(item,i) in items" :key="i"    @click="detail(item.id)" style="cursor: pointer;">
          <v-img
          class="itempro"
            :src="item.cover_image"
          ></v-img>
          <p>
            {{ item.name }}
          </p>
          <span style="color:#BDBDBD" v-if="item.current_price">
            {{ Math.trunc(item.current_price) }}
          </span>
          <span style="color:#BDBDBD" v-else>
            {{ Math.trunc(item.base_price) }}
          </span>
        </v-col>
    
      </v-row>
    </v-card>
  </template>
  <script>
import CrudDataService from '@/Service/CrudDataService';

  export default {
    data() {
      return {
        items:[]
      };
    },
    methods:{
      async allproduct(){
      let res= await CrudDataService.getAll('products');
      this.items=res.data.data.data.slice(0, 6)
    },
    detail(id) {
      this.$router.push({ name: "Detail", params: { id } });
      setTimeout(() => {
            window.location.reload();
          }, 200);
    },
    },
    mounted(){
    this.allproduct()
  }
  };
  </script>
<style>
.itempro{
    border-radius: 6px;
    background: #eee;
    object-fit: contain;

}
@media (max-width: 550px) {
  .itempro{
    object-fit: cover;

}  
}
</style>