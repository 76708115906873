<template>
    <v-container>
      <Cart />
    </v-container>
</template>

<script>
import { defineComponent } from "vue";
import Cart from "../components/Cart/Cart.vue";

export default defineComponent({
  components: {
    Cart,
  },
});
</script>
