<template>
  <section class="mt-8 slide" data-aos-offset="200" data-aos="fade-up" v-if="slidebanner.value">
    <v-row align="center" class="">
      <v-col cols="12" md="8" sm="7" >
     
          <p style="font-size: 21px; color: #fff" class="pa-md-5 pa-2 ps-md-10 ps-5">
            {{ slidebanner.value?slidebanner.value:'' }}
          </p>
      </v-col>
      <v-col cols="12" md="4" sm="5" class="py-0 text-center" >
    
            <v-btn
              style="background-color: #474749;;
             "
              class="text-white ma-md-0 ma-5"
              width="120px"
              @click="navigateTo()"
            >
            {{ $t('Shop_now') }}
              
            </v-btn>
      </v-col>
    </v-row>
  </section>
</template>
  <script>
  import CrudDataService from "@/Service/CrudDataService";
  import AOS from "aos";
  import image from "../../../src/assets/image/Rectangl.png";
  
  export default {
    data() {
      return {
        slide: image,
        slidebanner: "",
      };
    },
    methods: {
      navigateTo() {
        this.$router.push({ name: "Product" });
      },
      async banner() {
        let res = await CrudDataService.getAll("settings");
        res.data.data.forEach((Element) => {
          if (Element.key === "banner_2") {
          this.slidebanner = Element;
        }
        });
      },
    },
    mounted() {
      this.banner();
      AOS.init();
    },
  };
  </script>
  <style>
  .slide {
    background: url("../../assets/image/Rectangle.png");
  }
  
  @media (max-width: 767.98px) {
    .slide .v-img__img--contain {
      display: none;
    }
  }
  </style>
  