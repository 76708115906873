<template>
  <section>
    <v-row v-if="product">
      <v-col cols="12" md="5" >
        <!-- <v-carousel v-model="activeSlide" hide-delimiters class="carou_product">
  <v-carousel-item
  v-for="image in product.images"
    :src="image.path"
    :key="image.id"
    cover
  >
  </v-carousel-item>
  </v-carousel> -->
        <!-- <v-img
          v-if="fileType(largeImage) === 'image'"
          :src="largeImage"
          class="img-responsive mb-5 rounded"
          height="550"
          aspect-ratio="1.5"
          cover
        >
        </v-img> -->
        <img  v-if="fileType(largeImage) === 'image'" 
        :src="largeImage"
          class="img-responsive mb-5 rounded w-100"
          
          />
        <video
          v-else-if="fileType(largeImage) === 'video'"
          :src="largeImage"
          class="img-responsive mb-5 w-100 rounded "
          height="650"
          controls
        ></video>

        <!-- <v-img
      :src="largeImage"
      class="img-responsive mb-5"
      height="650"
      aspect-ratio="1.5"
      style="border-radius: 25px;"
      cover
    ></v-img> -->
        <v-carousel
          hide-delimiters
          hide-delimiter-background
          show-arrows
          :show-arrows-on-hover="true"
          height="200"
          class="changeimgcarous"
          cycle
        >
          <!-- Divide images into chunks and create a carousel item for each chunk -->
          <v-carousel-item
            v-for="(chunk, chunkIndex) in chunkedImages"
            :key="`chunk-${chunkIndex}`"
          >
            <v-row no-gutters class="flex-nowrap">
              <v-col v-for="image in chunk" :key="image.id" cols="4">
                <img
                  v-if="fileType(image.path) === 'image'"
                  :src="image.path"
                  class="small-image rounded"
                  cover
                  height="100%"
                  @click="updateLargeImage(image.path)"
                />

                <video
                  v-else-if="fileType(image.path) === 'video'"
                  :src="image.path"
                  class="small-image rounded"
                  cover
                  height="100%"
                  @click="updateLargeImage(image.path)"
                ></video>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col cols="12" md="7" >
        <v-card>
          <v-col cols="12" lg="8" sm="12">
            <v-card-item class="px-sm-12 px-4 pt-0">
              <h4>{{ product.name }}</h4>
            </v-card-item>
            <v-card-text class="px-sm-10 px-4">
              <v-row align="center" class="mx-0">
                <v-rating
                  :model-value="product.rating"
                  color="amber"
                  density="compact"
                  half-increments
                  readonly
                  size="small"
                ></v-rating>

                <div class="ms-4" style="color: #ff9017" v-if="product.rating">
                  {{ product.rating }}
                </div>
                <v-icon
                  icon="mdi-circle-medium"
                  color="#DEE2E7"
                  class="d-sm-inline d-none"
                  v-if="product.total_orders_quantity"
                ></v-icon>

                <div
                  style="color: #8b96a5"
                  class=""
                  v-if="product.total_orders_quantity"
                >
                  <v-icon icon="mdi-basket-outline"></v-icon>
                  {{ product.total_orders_quantity }}
                </div>
              </v-row>
              <v-row
                align="center"
                justify="space-between"
                class="mx-0 pt-6 pb-2"
                style="width: 350px"
              >
                <div class="titleh2">{{ $t("price") }}</div>
                <v-card-text class="d-flex" v-if="product.discounted_price">
                  <p
                    v-if="product.base_price"
                    class="text-decoration-line-through px-1 text-red"
                  >
                    {{
                      price ? Math.trunc(price) : Math.trunc(product.base_price)
                    }}
                    {{ $t("rs") }}
                  </p>
                  <p>
                    {{
                      price
                        ? Math.trunc(price)
                        : Math.trunc(product.discounted_price)
                    }}
                    {{ $t("rs") }}
                  </p>
                </v-card-text>
                <v-card-text class="" v-else>
                  <p>
                    {{
                      price ? Math.trunc(price) : Math.trunc(product.base_price)
                    }}
                  </p>
                </v-card-text>
              </v-row>
            </v-card-text>
            <v-divider class="mx-4 mb-1"></v-divider>
            <v-card-text class="px-sm-10 px-3">
             
              <div class="d-flex flex-sm-row flex-column justify-space-between w-100">
                <div class="titleh2 mb-2">{{ $t("Type") }}</div>
                <div
                  v-for="categorie in product.categories"
                  :key="categorie.id"
                  class="mb-2"
                >
                  {{ categorie.name }}
                </div>
              </div>
               
            </v-card-text>
            <v-divider class="mx-4 mb-1"></v-divider>
            <v-card-text class="px-sm-10 px-3 py-5">
              <v-row align="start" justify="space-between" class="mx-0 pb-2">
                <div class="titleh2">{{ product.first_option?product.first_option.value:'' }}</div>
                <!-- {{ product.variants[0] }} -->
                <div>
                  <div class="d-flex flex-column">
                    <div class="d-flex flex-row my-2" v-if="product.first_option">
                      <div
                        v-for="item in product.first_option.values"
                        :key="item.id"
                      >
                        <v-avatar
                          :style="{
                            backgroundColor: item.color,
                            cursor: 'pointer',
                          }"
                          class="mx-2"
                          size="25"
                          @click="selectColor(product.variants, item.id, item)"
                          v-if="item.color"
                          :class="{ activeborder: item.active }"
                        >
                        </v-avatar>
                        <span
                          v-else
                          :class="{ active: item.active }"
                          @click="selectColor(product.variants, item.id, item)"
                          class="sizing"
                          >{{ item.value }}</span
                        >
                      </div>
                    </div>
                    <div class="d-flex my-2">
                      <div
                        v-for="option in sizeoption"
                        :key="option.value_id"
                        :class="{ active: option.active }"
                        @click="addactive(option, option.value_id)"
                      >

                        <v-avatar
                          v-if="option.color"
                          :style="{
                            backgroundColor: option.color,
                            cursor: 'pointer',
                          }"
                          class="mx-2"
                          size="25"
                          :class="{ activeborder: option.active }"
                        >
                        </v-avatar>
                      
                        <span
                          class="sizing"
                          :class="{ active: option.active }"
                          v-else
                          >{{ option.value }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </v-row>
            </v-card-text>
            <v-divider class="mx-4 mb-1"></v-divider>
            <v-card-text class="px-sm-10 px-4">
              <div class="titleh2 pb-3">
                {{ $t("Product_information") }}
              </div>
              <div v-html="product.description"></div>
            </v-card-text>
          </v-col>
          <v-card-actions
            style="justify-content: end; align-items: end"
            class="flex-column"
          >
            <v-btn
              style="background-color: #a6792b; color: white"
              class="mb-3"
              width="165"
              variant="text"
              @click="addcart(product.id, product.variants)"
            >
              {{ $t("Add_cart") }}
              <v-icon icon="mdi-basket-outline" class="ms-2"></v-icon>
            </v-btn>

            <v-btn
              style="border: 1px solid #a6792b; color: #a6792b"
              width="165"
              variant="text"
              @click="toggleHeart(product)"
            >
              {{ $t("Save_later") }}
              <v-icon :icon="getIcon(product)" class="ms-2"></v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <section
      class="position-relative"
      style="height: 100vh; display: grid; place-items: center"
      v-else
    >
      <section class="cate"></section>
      <img :src="info" width="120" />
    </section>
    <v-snackbar v-model="showSnackbar" color="#A6792B">
      {{ $t("Added_successfully") }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="showSnackbarmessage" color="#A6792B">
      {{ messagecantadd }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" v-bind="attrs" @click="showSnackbarmessage = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
import info from "../../assets/image/Into.gif";
export default {
  data() {
    return {
      activecolor: false,
      matchyarray: [],
      sizeoption: [],
      largeImage: "",
      info,
      variantitemid: null,
      activeSlide: 0,
      product: "",
      image: "",
      showSnackbar: false,
      showSnackbarmessage: false,
      messagecantadd: "",
      optionsizeid: null,
      optioncolorid: null,
      price: "",
    };
  },
  computed: {
    chunkedImages() {
      const chunkSize = 3;
      return this.chunkArray(this.product.images, chunkSize);
    },
  },
  methods: {
    addactive(option, id) {
      if (!("active" in option)) {
        option.active = false;
      }
      this.sizeoption.forEach((value) => {
        value.active = false;
      });
      option.active = true;
      this.optionsizeid = id;
      this.addprice();
    },
    addprice() {
      this.product.variants.forEach((element) => {
        let sizeMatch = false;
        let colorMatch = false;
        element.options.forEach((oneopt) => {
          if (oneopt.value_id === this.optionsizeid) {
            sizeMatch = true;
          }
          if (oneopt.value_id === this.optioncolorid) {
            colorMatch = true;
          }
        });
      if (sizeMatch && colorMatch) {
          if (element.price) {
            this.price = element.price;
          } else {
            this.price = null;
          }
        }
      });
    },
    selectColor(variant, id, item) {
      this.optioncolorid = id;
      this.price = null;
     
      if (!("active" in item)) {
        item.active = false;
      }
      this.product.first_option.values.forEach((value) => {
        value.active = false;
      });
      item.active = true;
      this.sizeoption = [];

      variant.forEach((element) => {
        const matchingSizing = element.options.find(
          (sizing) => sizing.value_id === id
        );  
      
        console.log(element);
        if (matchingSizing) {
          this.price=element.price;
          element.options.forEach((sizing) => {
            console.log(sizing);
            if (sizing.value_id !== id) {
              this.sizeoption.push(sizing);
            }
          });
        }
      });
    },

    fileType(path) {
      const extension = path.split(".").pop().toLowerCase();
      if (["png", "jpg", "jpeg", "gif"].includes(extension)) {
        return "image";
      } else if (["mp4", "avi", "webm"].includes(extension)) {
        return "video";
      }
      return "unknown";
    },
    chunkArray(arr, size) {
      return arr.reduce((acc, e, i) => {
        return i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc;
      }, []);
    },
    updateLargeImage(image) {
      this.largeImage = image;
      this.updateViewers(image);
    },
    updateViewers(image) {
      let viewers = this.$refs.viewer;
      if (viewers && viewers.length) {
        viewers.forEach((viewer) => {
          viewer.src = image;
        });
      }
    },
    async addcart(id, variants) {
      variants.forEach((element) => {
        console.log(element.options);
        let sizeMatch = false;
        let colorMatch = false;
        element.options.forEach((oneopt) => {
          if (oneopt.value_id === this.optionsizeid) {
            sizeMatch = true;
          }
          if (oneopt.value_id === this.optioncolorid) {
            colorMatch = true;
          }
        });

        if (sizeMatch && colorMatch) {
          this.variantitemid = element.id;
        }
      });
      let res = await CrudDataService.create(`cart`, {
        product_id: id,
        quantity: 1,
        product_variant_id: this.variantitemid,
      })
        .then((res) => {
          if (res.data.success) {
            this.showSnackbar = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.showSnackbarmessage = true;
          if (err.data) {
          this.messagecantadd = err.data.message;
            
          }
        });
    },
    async singlrproduct() {
      let res = await CrudDataService.get(
        "products",
        `${this.$route.params.id}`
      );
      this.product = res.data.data;
      // this.image = res.data.data.cover_image;
      this.largeImage = res.data.data.cover_image;
    },
    async toggleHeart(product) {
      let res = await CrudDataService.create(
        `products/${product.id}/favorites`,
        ""
      ).then(() => {
        product.isfavourite = !product.isfavourite;
      });
    },
    getIcon(product) {
      return product.isfavourite ? "mdi-heart" : "mdi-heart-outline";
    },
  },
  mounted() {
    this.singlrproduct();
  },
};
</script>
<style>
.activeborder {
  border: 2px solid #0d0d0d;
  border-radius: 50%;
  padding: 1px;
}
.sizing {
  border: 1px solid #ccc;
  background-color: white;
  padding: 2px 12px;
  margin: 5px;
  border-radius: 25px;
  cursor: pointer;
}
.sizing:hover {
  border: 1px solid #0d0d0d;
}
.sizing.active {
  border: 2px solid #0d0d0d;
}
.itemactive.active {
  border: 2px solid #0d0d0d;
}
.avatar--color {
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.avatar--color:hover {
  transform: scale(1.1);
}
.img-responsive .v-img__img--cover {
  object-fit: fill;
}
.changeimgcarous .v-btn--icon.v-btn--density-default {
  transform: rotateY(180deg);
}
.small-image {
  width: 150px;
  height: 150px;
}
.carou_product .v-btn--icon.v-btn--density-default {
  transform: rotateY(180deg) !important;
}
[dir="ltr"] .carou_product .v-btn--icon.v-btn--density-default {
  transform: none !important;
}
.titleh2 {
  color: #8b96a5;
}
select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-color: #fafafa;
  height: 45px;
  width: 30%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 20%;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 0;
}
</style>
