// Styles
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css' 
import 'vuetify/styles'
import '../assets/css/style.css'
import { VOtpInput } from 'vuetify/labs/VOtpInput'
import { VDatePicker } from 'vuetify/labs/VDatePicker'
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader'
// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides,
  components: {
    VOtpInput,
    VDatePicker,
    VSkeletonLoader,

  },
}

)
