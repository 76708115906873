<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-card style="background: #fff" class="pa-3 rounded-0">
    <v-row class="text-center">
      <v-col cols="12" class="pb-0">
        <v-carousel
          hide-delimiter-background
          :show-arrows="false"
          class="carousel caroitem"
          v-model="activeSlide"
        >
          <v-carousel-item
            v-for="(slide, i) in slides"
            :key="i"
            :src="slide.image"
            cover
          >
            <a :href="slide.link" target="_blank">
              <div class="d-flex fill-height justify-center align-center">
                <div class="text-white text-center textheader_pos">
                  {{ slide.title }}
                </div>

              </div>
            </a>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import CrudDataService from "@/Service/CrudDataService";
import image from "../../../src/assets/image/Avatar.png";
import Group from "../../../src/assets/image/Group.png";
import { useAuth } from "@/http-common";
import { ref } from "vue";
export default {
  data: () => ({
    logo: image,
    card: Group,
    slides: [],
    username: "",
    activeSlide: 0,
  }),
  setup() {
    const { isLoggedIn } = useAuth();
    const unLogged = ref(!isLoggedIn());
    return {
      unLogged,
    };
  },
  methods: {
    account() {
      this.$router.push({ name: "Login" });
    },
    signlink() {
      this.$router.push({ name: "SignUp" });
    },
    async useracount() {
      const { isLoggedIn } = useAuth();
      if (ref(isLoggedIn()).value) {
        let res = await CrudDataService.getAll("user/profile")
          .then((response) => {
            this.username =
              response.data.data.fname + " " + response.data.data.lname;
          })
          .catch(() => {});
      }
    },
    async slider() {
      let response = await CrudDataService.getAll("sliders");
      this.slides = response.data.data;
    },
  },
  mounted() {
    this.useracount();
    this.slider();
  },
};
</script>
<style>
.carousel.caroitem {
  height: 385px !important;
}
.carousel .headerimg .v-img__img--contain {
  object-fit: cover;
}
.carousel .v-btn--variant-text .v-btn__overlay {
  background: transparent;
}
.carousel .v-btn__content {
  border: 1px solid #474749;
  border-radius: 50%;
  font-size: 8.5px;
}
.carousel .v-btn--icon .v-icon {
  color: transparent;
}
.carousel .v-responsive.v-img {
  height: 100% !important;
}

.carousel .v-carousel__controls {
  background: #fff;
}
.carousel .v-btn--icon.v-btn--density-default {
  width: 20px;
  height: 20px;
}
::v-deep .v-window-item.v-window-item--active .v-carousel-control {
  color: #474749;
}
::v-deep .v-window-item.v-window-item--active .v-carousel-control .v-icon {
  color: #474749;
}
.carousel .v-carousel__controls__item.v-btn.v-btn--active {
  color: #474749;
}
.carousel.caroitem .v-img__img--cover {
  object-fit: fill !important;
  height: 88%;
}
.textheader_pos {
  position: absolute;
  bottom: 15%;
  right: 20%;
  left: 20%;
  font-size: 30px;
}
@media (max-width: 991.98px) {
  .textheader_pos {
    position: absolute;
    bottom: 15%;
    right: 20%;
    left: 20%;
    font-size: 24px;
  }
}
@media (max-width: 767.98px) {
  .uptext {
    height: 36% !important;
  }
  .text-group {
    font-size: 20px !important;
    padding: 10px !important;
  }
  .carousel.caroitem {
    height: 280px !important;
  }
  .carousel.caroitem .v-img__img--cover {
    object-fit: fill !important;
    height: 75%;
  }
  .textheader_pos {
    bottom: 26%;
    font-size: 20px;
    right: 15%;
    left: 15%;
  }
}
@media (max-width: 560px) {
  .uptext {
    height: 48% !important;
  }
  .textheader_pos {
    bottom: 26%;
    font-size: 16px;
    right: 10%;
    left: 10%;
  }
  .carousel .v-responsive__content .text-h4 {
    font-size: 1rem !important;

    bottom: 28% !important;
  }
  .carousel.caroitem {
    height: 200px !important;
  }
}
@media (max-width: 400px) {
  .carousel.caroitem {
    height: 170px !important;
  }
  .textheader_pos {
    bottom: 29%;
    font-size: 10px;
    right: 2%;
    left: 2%;
  }
}
</style>
