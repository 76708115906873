<template>
      <Login />
</template>

<script>
import { defineComponent } from "vue";
import Login from "../components/Auth/Login/Login.vue";

export default defineComponent({
  components: {
    Login,
  },
});
</script>
