<template>
  <section class="footer">
    <v-container>
      <v-row>
        <v-col cols="12" md="3">
          <v-img :src="imagelogo" alt="" v-if="imagelogo" width="130" height="80" contain ></v-img>
          <div
            class="py-3"
            style="font-size: 15px"
            v-html="footerparagraph.value"
          ></div>
          <div class="d-flex flex-wrap">
            <v-avatar
              rounded="circle"
              color="#BDC4CD"
              class="mb-1"
              v-if="youtube.value"
            >
              <a
                :href="`${youtube.value}`"
                target="_blank"
                class="text-white text-decoration-none"
              >
                <v-icon
                  icon="mdi-youtube"
                  color="#fff"
                  style="margin-bottom: 4px; font-size: 20px"
                ></v-icon>
              </a>
            </v-avatar>
            <v-avatar
              rounded="circle"
              color="#BDC4CD"
              class="mb-1"
              v-if="instagram.value"
            >
              <a
                :href="`${instagram.value}`"
                target="_blank"
                class="text-white text-decoration-none"
              >
                <v-icon
                  icon="mdi-instagram"
                  color="#fff"
                  style="margin-bottom: 4px; font-size: 20px"
                ></v-icon>
              </a>
            </v-avatar>
            <v-avatar
              rounded="circle"
              color="#BDC4CD"
              class="mb-1"
              v-if="linkedin.value"
            >
              <a
                :href="`${linkedin.value}`"
                target="_blank"
                class="text-white text-decoration-none"
              >
                <v-icon
                  icon="mdi-linkedin"
                  color="#fff"
                  style="margin-bottom: 4px; font-size: 20px"
                ></v-icon>
              </a>
            </v-avatar>
            <v-avatar
              rounded="circle"
              color="#BDC4CD"
              class="mb-1"
              v-if="twitter.value"
            >
              <a
                :href="`${twitter.value}`"
                target="_blank"
                class="text-white text-decoration-none"
              >
                <v-icon
                  icon="mdi-twitter"
                  color="#fff"
                  style="margin-bottom: 4px; font-size: 20px"
                ></v-icon>
              </a>
            </v-avatar>
            <v-avatar
              rounded="circle"
              color="#BDC4CD"
              class="mb-1"
              v-if="facebook.value"
            >
              <a
                :href="`${facebook.value}`"
                target="_blank"
                class="text-white text-decoration-none"
              >
                <v-icon
                  icon="mdi-facebook"
                  color="#fff"
                  style="margin-bottom: 4px; font-size: 20px"
                ></v-icon>
              </a>
            </v-avatar>
            <v-avatar rounded="circle" color="#BDC4CD" class="mb-1" v-if="tiktok.value">
              <a
                :href="`${tiktok.value}`"
                target="_blank"
                class="text-white text-decoration-none"
              >
                <i
                  class="fa-brands fa-tiktok"
                  color="#fff"
                  style="margin-bottom: 4px; font-size: 20px"
                ></i>
              </a>
            </v-avatar>
            <v-avatar rounded="circle" color="#BDC4CD" class="mb-1" v-if="snapchat.value">
              <a
                :href="`${snapchat.value}`"
                target="_blank"
                class="text-white text-decoration-none"
              >
                <v-icon
                  icon="mdi-snapchat"
                  color="#fff"
                  style="margin-bottom: 4px; font-size: 20px"
                ></v-icon>
              </a>
            </v-avatar>

            <v-avatar rounded="circle" color="#BDC4CD" class="mb-1" v-if="whatsapp_link.value">
              <a
                :href="`https://wa.me/${whatsapp_link.value}`"
                target="_blank"
                class="text-white text-decoration-none"
              >
                <v-icon
                  icon="mdi-whatsapp"
                  color="#fff"
                  style="margin-bottom: 4px; font-size: 20px"
                ></v-icon>
              </a>
            </v-avatar>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <p class="pt-2 font-weight-medium">{{ $t("Important_links") }}</p>
          <v-list style="background: transparent">
            <v-list-item-subtitle
              class="py-2"
              color="#8B96A5"
              v-for="item in items"
              :key="item.id"
            >
              <span @click="gotopage(item.id)" class="cursor">
                {{ item.title }}
              </span>
            </v-list-item-subtitle>
          </v-list>
        </v-col>
        <v-col cols="12" md="3">
          <p class="pt-2 font-weight-medium">
            {{ $t("Contact") }}
          </p>

          <v-list style="background: transparent">
            <v-list-item-subtitle class="py-2" color="#8B96A5">
              <a
                :href="`mailto:${email.value}`"
                class="text-black"
                target="_blank"
              >
                <v-icon icon="mdi-email-outline"></v-icon>
                {{ email.value }}
              </a>
            </v-list-item-subtitle>

            <v-list-item-subtitle class="py-2" color="#8B96A5">
              <a
                :href="`tel:+${phone.value}`"
                class="text-black"
                target="_blank"
              >
                <v-icon icon="mdi-phone" class="mx-1"></v-icon>
                {{ phone.value }}
              </a>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="py-2" color="#8B96A5">
              <v-icon> mdi-map-marker-outline </v-icon>
              {{ address.value }}
            </v-list-item-subtitle>
          </v-list>
        </v-col>
        <!-- <v-col cols="12" md="2" >
        <p class="pt-2 font-weight-medium">
            معلوماتنا

        </p>
        <v-list style="background: transparent;">
            <v-list-item-subtitle class="py-2" color="#8B96A5">
                المساعدة 
            </v-list-item-subtitle>
            <v-list-item-subtitle class="py-2" color="#8B96A5">
                الشحن 
            </v-list-item-subtitle>
            <v-list-item-subtitle class="py-2" color="#8B96A5">
                الشحن 
            </v-list-item-subtitle>

        </v-list>
      </v-col> -->
        <v-col cols="12" md="3" class="text-center">
          <p class="pt-2 font-weight-medium">{{ $t("Get_app") }}</p>
          <a href="https://apps.apple.com/eg/app/x5smart/id6477860968" target="_blank">
            <v-img :src="marketbutton" width="50%" class="mx-auto my-3"></v-img>
          </a>
          <v-img :src="Group" width="50%" class="mx-auto my-3"></v-img>
        </v-col>
      </v-row>
    </v-container>
    <div
      class="px-4 py-2 text-center w-100 text-white"
      style="background-color: #A6792B"
    >
    ©
    {{ currentyear }}
       code 7x
    </div>
  </section>
</template>
<script>
import image from "../../../src/assets/image/logo.png";
import market from "../../../src/assets/image/market-button.png";
import Gro from "../../../src/assets/image/Gro.png";
import CrudDataService from "@/Service/CrudDataService";
export default {
  data: () => ({
    currentyear: new Date().getFullYear(),
    logo: image,
    Group: Gro,
    marketbutton: market,
    footerdata: [],
    email: "",
    address: "",
    phone: "",
    items: [],
    footerparagraph: "",
    facebook: "",
    instagram: "",
    twitter: "",
    youtube: "",
    linkedin: "",
    tiktok: "",
    snapchat: "",
    whatsapp_link: "",
    imagelogo: "",
  }),
  methods: {
    gotopage(id) {
      this.$router.push({ name: "Page", params: { id } });
    },
    async allpages() {
      let res = await CrudDataService.getAll("pages");
      this.items = res.data.data;
    },
    async footer() {
      let res = await CrudDataService.getAll("settings");
      res.data.data.forEach((Element) => {
        if (Element.page === "footer") {
          this.footerdata.push(Element);
        } else if (Element.page === "general") {
          if (Element.key === "logo") {
            this.imagelogo = Element.image;
          }
        }
      });
      if (this.footerdata.length > 0) {
        this.footerdata.forEach((Element) => {
          if (Element.key === "email") {
            this.email = Element;
          } else if (Element.key === "phone") {
            this.phone = Element;
          } else if (Element.key === "address") {
            this.address = Element;
          } else if (Element.key === "footer_paragraph") {
            this.footerparagraph = Element;
          } else if (Element.key === "facebook") {
            this.facebook = Element;
          } else if (Element.key === "twitter") {
            this.twitter = Element;
          } else if (Element.key === "instagram") {
            this.instagram = Element;
          } else if (Element.key === "youtube") {
            this.youtube = Element;
          } else if (Element.key === "linkedin") {
            this.linkedin = Element;
          } else if (Element.key === "tiktok") {
            this.tiktok = Element;
          } else if (Element.key === "snapchat") {
            this.snapchat = Element;
          } else if (Element.key === "whatsapp_link") {
            this.whatsapp_link = Element;
          }
        });
      }
    },
  },

  mounted() {
    this.footer();
    this.allpages();
  },
};
</script>
<style>
.footer .fab,
.fa-brands {
  font-family: "Font Awesome 6 Brands" !important;
}
.footer .v-avatar.v-avatar--density-default {
  height: 33px;
  width: 33px;
  margin-left: 8px;
}
.cursor {
  cursor: pointer;
}
</style>
