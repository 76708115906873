<template>
  <section v-if="loading" style="background: #eeeeee">
    <v-container>
      <Header />
      <Categories />
      <!-- <Offers /> -->
      <Latestpackages />
      <Slide/>
      <Most/>
      <SlideBanner/>
      <Product/>
      <!-- <Descution/> -->
      <Secoffer/>
    </v-container>
    <Subscrib/>
  </section>
  <section v-else class="position-relative" style="height: 100vh; display: grid; place-items: center;">
    <section class="cate"></section>
    <!-- <v-progress-circular indeterminate color="#d6487c8a"></v-progress-circular> -->
    <img :src="info" width="120">
  </section>
</template>

<script>
import { defineComponent } from "vue";
import info from "../assets/image/Into.gif";
import Subscrib from "../components/HOME/Subscrib.vue";
import Header from "../components/HOME/Header.vue";
import Categories from "../components/HOME/Categories.vue";
import Latestpackages from "../components/HOME/Latestpackages.vue";
import Most from "../components/HOME/Most.vue";
import Product from "../components/HOME/Product.vue";
import Slide from "../components/HOME/Slide.vue";
import SlideBanner from "../components/HOME/SlideBanner.vue";
import Secoffer from "../components/HOME/Secoffer.vue";
import CrudDataService from "@/Service/CrudDataService";

export default defineComponent({
  name: "HomeView",
  data() {
    return {
      loading: false,
      info,
    };
  },
  components: {
    Header,
    Categories,
    Latestpackages,
    Most,
    Product,
    Slide,
    SlideBanner,
    Secoffer,
    Subscrib,
  },
  async mounted() {
    await CrudDataService.getAll("categories");
    setTimeout(() => {
      this.loading = true;
    }, 0);
  },
});
</script>
