<template>
  <section>
    <v-row v-if="items.length>0">
      <v-col cols="12" md="3" v-for="product in items" :key="product.id">
        <v-card
        class="mx-auto pb-4 mostcard mb-2"
            @click="detail(product.id)"
              flat
              style="height: 520px"
       
      >    
              <img   :src="product.cover_image" 
                height="300"
                class="grey lighten-2 w-100"
                style="object-fit: fill !important;"/>
              <div class="pa-3 pr-3 pb-0">
                <div class="d-flex justify-space-between align-center">
                <p @click="detail(product.id)" style="cursor: pointer">
                  {{ product.name }}
                </p>
              
              </div>
  <v-card-text  class="py-1 px-0 d-flex" v-if="product.discounted_price">
                  <p v-if="product.base_price" class="text-decoration-line-through text-red px-1">
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                <p class="px-1">
                  {{
                  Math.trunc(product.discounted_price)
                }}
                {{ $t('rs') }}

                </p>
               </v-card-text>
               <v-card-text  class="py-1 px-0 d-flex" v-else>
                  <p class="px-1">
                  {{
                  Math.trunc(product.base_price)
                }}
                {{ $t('rs') }}

                </p>
                
               </v-card-text>


         
              <div class="d-flex flex-column">
                <v-rating
                  color="amber"
                  density="compact"
                  
                  readonly
                  size="small"
                v-model="product.rating"
                  class="px-0"
                  hover
                ></v-rating>
                <p style="color: #ff9017" v-if="product.rating">
                  {{ product.rating }}
                </p>
               
               
              </div>

              <v-card-text class="py-0 line_text" >
                {{ product.description_without_html }}
              </v-card-text>
              <v-card-actions class="px-0 justify-center" >
                  <v-btn
                    style="
                      color: #A6792B;
                      border: 1px solid #A6792B;
                      border-radius: 20px;
                    "
                  @click="detail(product.id,product)"
                    > 
                    {{ $t('details') }}
                    
                    <v-icon icon="mdi-undo" class="px-3 icontransform"></v-icon>
                  </v-btn>
                </v-card-actions>
              </div>
           
            </v-card>
        
      </v-col>
    </v-row>
    <section v-else>
      
    </section>
    <v-snackbar v-model="showSnackbar" color="#A6792B">
{{$t('Added_successfully')}}
    <template v-slot:action="{ attrs }">
      <v-btn color="pink" v-bind="attrs" @click="showSnackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
  </section>
</template>
<script>
import CrudDataService from "@/Service/CrudDataService";
export default {
  data() {
    return {
      items: [ ],
      showSnackbar :false

    };
  },
  methods: {
    async addcart(id){
    let res=await CrudDataService.create(`cart`,{
        product_id: id,
        quantity: 1,
      })
      if (res.data.success) {
      this.showSnackbar=true
    }
    },
    removeFromFavorites(itemId) {
      this.items = this.items.filter((item) => item.id !== itemId);
    },
    async favorite(){
      let res = await CrudDataService.getAll('user/favorites').then((res)=>{
        res.data.data.data.forEach(element => {
        element.product_id
         CrudDataService.get('products',`${element.product_id}`)
         .then(response => {
          this.items.push(response.data.data);
          }).catch((err)=>{
            console.log(err);
            
          })
      });
      }).catch((err)=>{
            console.log(err);
            
          })
     
    },
    detail(id) {
      this.$router.push({ name: "Detail", params: { id } });
    },
  },
  mounted(){
    this.favorite()
  }
};
</script>
