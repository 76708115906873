<template>
    <v-container>
      <Braedcrumb />
      <Detail/>
      <Anotherproduct/>
    </v-container>
</template>

<script>
import { defineComponent } from "vue";
import Braedcrumb from "../components/Productdetail/Breadcrumb.vue";
import Detail from "../components/Productdetail/Detail.vue";
import Anotherproduct from "../components/Productdetail/Anotherproduct.vue";
export default defineComponent({
  components: {
    Braedcrumb,
    Detail,
    Anotherproduct
  },
});
</script>
