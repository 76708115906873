import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import i18n from './i18n'
import "@vueform/multiselect/themes/default.css";
import './assets/css/style.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
loadFonts()

// router.beforeEach((to, from, next) => {
  console.log(localStorage.getItem('lang'));
  const language = localStorage.getItem('lang') || 'ar'; // Default to 'en' if not set
  document.documentElement.setAttribute('dir', language === 'ar' ? 'rtl' : 'ltr');


loadFonts()
createApp(App)
  .use(router)
  .use(vuetify)
  .use(i18n)
  .use(AOS)
  .mount('#app')