<template>
    <v-container>
      <Profile />
    </v-container>
</template>

<script>
import Profile from "../components/Profile/Profile.vue";

export default ({
  components: {
    Profile,
  },
});
</script>
