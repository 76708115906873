<template>
  <section
    class="position-relative"
    style="height: 100vh; display: grid; place-items: center"
    v-if="loading"
  >
    <section class="cate"></section>
    <img :src="info" width="120" />
  </section>
  <section v-else>
    <section v-if="items.length > 0">
      <h3 class="mb-3">{{ $t("cart") }}</h3>
      <section class="bg-white rounded">
        <v-card
          class="pa-3"
          v-for="item in items"
          :key="item.id"
          style="background-color: transparent"
          elevation="0"
        >
          <v-row>
            <v-col cols="12" sm="2" xs="12">
              <v-img
                :src="item.cover_image"
                height="200"
                cover
                class="rounded"
              ></v-img>
            </v-col>
            <v-col cols="12" md="8" sm="7" xs="12">
              <div width="80%">
                <p @click="detail(item.product_id)" style="cursor: pointer">
                  {{ item.name }}
                </p>
                <div  v-if="item.item.variant" class="d-flex py-3 align-center">
 <div v-for="onechoose in item.item.variant.options" :key="onechoose.id" >                  
                    <v-avatar
                          :style="{
                            backgroundColor: onechoose.color,
                            cursor: 'pointer',
                          }"
                          class="mx-2"
                          size="25"
                        v-if="onechoose.color"
                        :class="{ activeborder: onechoose.active }">
                        </v-avatar>
                        <span
                     v-else
                   :class="{ active: onechoose.active }"
                     class="sizing">{{ onechoose.value }}</span
                   >
                   </div>
                </div>
                 
                <p class="text-grey-lighten-1">
                  {{ item.text }}
                </p>
                <v-btn
                  class="text-red mt-3"
                  variant="outlined"
                  style="border: 1px solid#DEE2E7"
                  @click="remove(item.id)"
                >
                  {{ $t("delete") }}
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12" md="2" sm="3" xs="12">
              <div class="d-flex flex-sm-column justify-space-between">
                <v-card-title>
                  {{ item.total_price ? item.total_price : "" }}
                  {{ $t("rs") }}
                </v-card-title>
                <div class="d-flex justify-space-between align-center">
                  <button @click="increaseQuantity(item)">
                    <v-icon> mdi-plus-box </v-icon>
                  </button>
                  <span>{{ $t("Quantity") }}</span>

                  <input
                    type="number"
                    id="quantity"
                    min="1"
                    class="form-control w-25 text-center"
                    v-model.number="item.quantity"
                    disabled
                  />
                  <button @click="decreaseQuantity(item)">
                    <v-icon> mdi-minus-box </v-icon>
                  </button>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-divider class="mx-4 my-3"></v-divider>
        </v-card>
        <div
          class="pa-md-5 pa-2 pt-2 d-flex flex-md-row flex-column justify-md-space-between justify-center align-items-center"
        >
          <v-btn
            class="text-red"
            variant="outlined"
            style="border: 1px solid#DEE2E7"
            @click="removeall()"
          >
            {{ $t("delete_all") }}
          </v-btn>
          <v-btn
            style="background-color: #a6792b; color: white"
            width="200"
            variant="text"
            class="mr-auto ml-0 mt-md-0 mt-2"
            @click="golast"
          >
            {{ $t("Previous_orderes") }}
            <v-icon icon="mdi-arrow-left" class="ms-2 arrow"></v-icon>
          </v-btn>
        </div>
      </section>
      <section class="bg-white rounded mt-5 pa-4 cobonn" style="width: 35%">
        <p class="pb-4">
          {{ $t("discount_coupon") }}
        </p>
        <div class="d-flex justify-center align-center border rounded">
          <v-text-field
            density="compact"
            variant="solo"
            :placeholder="$t('Add_coupon')"
            class="pa-0 coupon"
            hide-details
            rounded="0"
            style="border-left: 1px solid #e0e0e0"
            v-model="coupon"
          >
          </v-text-field>
          <v-btn
            style="color: #a6792b"
            class="rounded-0"
            elevation="0"
            @click="applycoupon(coupon)"
          >
            {{ $t("Done") }}
          </v-btn>
        </div>
        <div>{{ message ? message : "" }}</div>
      </section>
      <section class="bg-white rounded mt-5 pa-4 total">
        <div class="d-flex justify-center align-center border rounded">
          <div class="w-75 pa-2 coupon" style="border-left: 1px solid #e0e0e0">
            {{ $t("Total_cart") }}
          </div>
          <div class="w-25 pa-2 text-center">
            {{ totalcart }}
            {{ $t("rs") }}
          </div>
        </div>
      </section>
      <div class="d-flex justify-end">
        <v-btn
          class="rounded-0 mt-5"
          style="background-color: #a6792b; color: white"
          width="200"
          height="45"
          variant="text"
          @click="completeroute(coupon)"
        >
          {{ $t("Complete_order") }}
        </v-btn>
      </div>
    </section>
    <section v-else class="my-md-16 py-md-1">
      <h3 class="pb-md-16">{{ $t("cart") }}</h3>
      <v-card class="hint py-2 mb-md-16" elevation="0">
        <v-card-text>
          {{ $t("nothing") }}
          {{ $t("products") }}
          {{ $t("now") }}
        </v-card-text>
      </v-card>
    </section>
    <v-snackbar
      v-model="showSnackbarmessage"
      color="#A6792B"
    >
      {{ messagecantadd }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" v-bind="attrs" @click="showSnackbarmessage = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>
<script>
import Multiselect from "@vueform/multiselect";
import CrudDataService from "@/Service/CrudDataService";
import info from "../../assets/image/Into.gif";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      texts: [1, 2, 3, 4, 5, 6],
      info,
      loading: false,
      items: [],
      totalcart: "",
      coupon: "",
      showSnackbarmessage: false,
      messagecantadd: "",
      message: "",
    };
  },
  methods: {
    applycoupon(coupon) {
      CrudDataService.create(`apply-coupon`, { coupon: coupon })
        .then((response) => {
          console.log(response.data);
          this.message = response.data.message;
          this.cart();
        })
        .catch((error) => {
          console.log(error.data.message);
          this.message = error.data.message;
        });
    },
    completeroute(coupon) {
      this.$router.push({ name: "Complete", params: { coupon } });
    },
    golast() {
      this.$router.push({ name: "Lastorder" });
    },
    detail(id) {
      this.$router.push({ name: "Detail", params: { id } });
    },
    remove(itemId) {
      CrudDataService.delete("cart", itemId);
      this.items = this.items.filter((item) => item.id !== itemId);
    },
    async cart() {
      this.loading = true; // Start loading
      try {
        let res = await CrudDataService.getAll(`cart?code=${this.coupon}`);
        console.log(res);
        this.items = res.data.data.items;
        this.totalcart = res.data.data.total;
      } catch (error) {
        console.error("Failed to fetch data:", error);
        // Handle error
      } finally {
        this.loading = false; // End loading regardless of success or failure
      }
    },
    async updatecart(id, qun,variant) {
      let res = await CrudDataService.put(`cart`, {
        product_id: id,
        quantity: qun,
        product_variant_id: variant,
      }).then((res)=>{
        this.items = res.data.data.items;
      this.totalcart = res.data.data.total;
      }).catch((err)=>{
        this.showSnackbarmessage = true;
        this.messagecantadd = err.data.message;

      })
  
    },
    increaseQuantity(item) {
      item.quantity++;
      item.total_price = item.final_unit_price * item.quantity;
      this.updatecart(item.product_id, item.quantity,item.variant_id);
    },
    decreaseQuantity(item) {
      if (item.quantity > 1) {
        item.quantity--;
        item.total_price = item.final_unit_price * item.quantity;
        this.updatecart(item.product_id, item.quantity,item.variant_id);
      }
    },
    removeall() {
      CrudDataService.deleteAll(`cart`);
      this.items = [];
      this.totalcart = "";
    },
  },
  mounted() {
    this.cart();
  },
};
</script>
<style>
.coupon .v-field--variant-solo,
.coupon .v-field--variant-solo-filled {
  box-shadow: none;
}
@media (max-width: 767.98px) {
  .cobonn {
    width: 100% !important;
  }
}
</style>
<style scoped>
.sizing:hover {
  border: 1px solid #ccc;
}
.hint {
  margin-bottom: 100px !important;
  background: #474749;
  color: white;
  text-align: center;
}
.hint .v-card-text {
  font-size: 18px;
}
</style>
