<template>
   <v-breadcrumbs :items="items">
      <template v-slot:title="{ item }">
        {{ $t(item.title) }}
      </template>
    </v-breadcrumbs>
    
</template>
<script>

export default {
data() {
 return {
     items: [
     {
       title: 'Home',
       disabled: false,
       href: '/',
     },
     {
       title: 'products',
       disabled: false,
       href: '/products',
     },
     {
       title: 'Product_details',
       disabled: false,
       href: '/detail',
     },
   ],
 };
},
};
</script>
<style >

 .v-breadcrumbs {
color: #8B96A5;
}
@media (max-width: 767.98px) {
  .breadd  {
    padding: 0;
}
.breadd .v-breadcrumbs-divider {
    padding: 0;
}
}
</style>